import { useMemo } from 'react';
import {
  SharedMessage as SharedMessageType,
  ResearchCardData,
  ResearchTaskCreationCard,
} from 'src/types';
import { prepareExternalModelsTabContent } from 'src/utils';
import { ResearchMedia } from './components/ResearchMedia';
import { ThreadResearchReferences } from 'src/components/ThreadResearchReferences/ThreadResearchReferences';
import { TabPanel, Tabs } from 'src/v2/commonComponents/Tabs';
import { MessageTabData, MessageTabs } from 'src/types/message';
import { renderMessageTabList } from '../../renderMessageTabList';
import { MessageContent } from '../MessageContent';
import { NINJA_AGENT_TITLE } from 'src/constants';

type ResearcherCardProps = {
  message: SharedMessageType;
};

export const ResearcherCard = ({ message }: ResearcherCardProps) => {
  const { research_card = { data: {} } } =
    message.payload as ResearchTaskCreationCard;

  const { search_result, deep_research_result, generative_ai_result } =
    research_card.data as ResearchCardData;

  const { referenced_model_summary } = generative_ai_result || {};
  const { references: external_models_result } = referenced_model_summary || {};

  const {
    web_page_references = [],
    image_references,
    video_references,
  } = search_result || {};

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(search_result
        ? [
            {
              tabName: MessageTabs.SEARCH_RESULT,
              iconName: 'ninjaOutlined',
              tabTitle: NINJA_AGENT_TITLE,
              content: search_result.summary || '',
              references: web_page_references,
            },
          ]
        : []),
      ...(deep_research_result
        ? [
            {
              tabName: MessageTabs.DEEP_RESEARCH,
              iconName: 'ninjaPixProLogo',
              tabTitle: 'Ninja SuperAgent',
              tabTitleClassName: 'nj-message--highlighted-text',
              content: deep_research_result?.summary || '',
              references: deep_research_result?.web_page_references,
            },
          ]
        : []),
      ...(external_models_result
        ? prepareExternalModelsTabContent(external_models_result).map(
            ({ tabName, tabTitle, iconName, content }) => ({
              tabName,
              iconName,
              tabTitle,
              content,
            }),
          )
        : []),
    ],
    [
      search_result,
      web_page_references,
      deep_research_result,
      external_models_result,
    ],
  );

  return (
    <Tabs<string> defaultActiveTab={MessageTabs.SEARCH_RESULT}>
      {renderMessageTabList({ data: tabsData })}

      {tabsData.map(({ tabName, content, references }) => (
        <TabPanel key={tabName} tab={tabName}>
          <div className="nj-thread-research-card">
            <MessageContent content={content} references={references} />

            {references && (
              <ThreadResearchReferences researchReferences={references} />
            )}

            {tabName === MessageTabs.SEARCH_RESULT && (
              <ResearchMedia
                images={image_references || []}
                videos={video_references || []}
              />
            )}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

import { Play } from '@phosphor-icons/react';
import Skeleton from 'react-loading-skeleton';
import { Icon } from 'src/components/Icon';
import classNames from 'classnames';
import { useState } from 'react';
import { AiGenerationStatus } from 'src/types/models/AiGenerationStatus';
import { SVG_SIZE_S } from 'src/constants';
import { ResearchMediaError } from '../ResearchMediaError';

const SVG_SIZE_30 = 30;

interface ResearchMediaThumbnailProps {
  thumbnail: string;
  title: string;
  isVideo?: boolean;
  onClick: () => void;
  icon?: string;
  status?: string;
  isPlacesInThread?: boolean;
}

export const ResearchMediaThumbnail = ({
  thumbnail,
  title,
  isVideo,
  onClick,
  icon,
  status,
  isPlacesInThread = false,
}: ResearchMediaThumbnailProps) => {
  const [isCropped, setIsCropped] = useState(false);

  const { lightTheme: baseLight } = {
    lightTheme: '#e8eaea',
  };

  const { lightTheme: highlightLight } = {
    lightTheme: '#ffffff',
  };

  const isLoadingImage = thumbnail.length === 0 && !isVideo;

  if (
    isCropped ||
    status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ||
    status === AiGenerationStatus.FAILURE
  ) {
    return (
      <div
        className="nj-thread-research-media--thumbnail placeholder"
        onClick={onClick}
      >
        <ResearchMediaError
          status={status}
          isPlacesInThumbnail={isPlacesInThread}
          mediaType="image"
        />
      </div>
    );
  }

  return (
    <div className="nj-thread-research-media--thumbnail">
      {isVideo && (
        <span className="nj-thread-research-media--thumbnail-icon">
          <Play size={SVG_SIZE_S} weight="fill" />
        </span>
      )}
      {icon && (
        <span
          className={classNames('nj-thread-research-media--thumbnail-icon', {
            centered: isLoadingImage,
          })}
        >
          <Icon size={isLoadingImage ? SVG_SIZE_30 : SVG_SIZE_S} type={icon} />
        </span>
      )}
      <div className="nj-gradient-overlay" />
      {isLoadingImage ? (
        <Skeleton
          baseColor={baseLight}
          highlightColor={highlightLight}
          duration={2}
          width="100%"
          height="100%"
          count={1}
        />
      ) : (
        <div className={'nj-thread-research-media--thumbnail-image-wrapper'}>
          <img
            className="nj-thread-research-media--thumbnail-image"
            alt={title}
            src={thumbnail}
            onClick={onClick}
            onError={() => {
              setIsCropped(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const preprocessLaTeX = (content: string) => {
  // Convert $ formulas to $$ formulas (and ignore converting for formulas like this $$...$$)
  // const escapedContent = content.replace(
  //   /(\$)(?!\$)([\w +\-\\{}=()_*^,./]+)(\$(?!\$))(?=[\D])/g,
  //   (_, start, formula, end) => `\\(${formula}\\) `,
  // );

  // Convert block formulas
  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `\n$$\n${equation.trim()}\n$$\n`,
  );

  // Convert inline formulas
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$$${equation.trim()}$$`,
  );

  return inlineProcessedContent;
};

export const CITATION_REGEX = /\[citation:(\d+)\]/g;
export const CITATION_TITLE_REGEX = /^citation:(\d+)$/;
export const CODE_REGEX = /```(\w+) /g;

/**
 * @deprecated
 */
export const CODE_WITHOUT_NEWLINE_REGEX = /(\\n)?\s*```/g;
/**
 * @deprecated
 */
export const CODE_LANGUAGE_REGEX = /```(\w+)?\n([\s\S]*?)```/g;

import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT, SVG_SIZE_L, SVG_SIZE_XL } from 'src/constants';
import './ModalWithGallery.scss';
import { MouseEvent, useMemo, useRef, useContext, useState } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  ImageGenRenderData,
  isExternalModelReference,
  isImageGeneratedItem,
  isSearchImageReference,
  isSearchVideoReference,
  isSearchWebPageReference,
  ModalGalleryItemType,
  ModalGalleryType,
  SharedMessage,
} from 'src/types';
import { ResearchMediaThumbnail } from 'src/components/SharedMessage/components/ResearcherCard/components/ResearchMediaThumbnail';
import classNames from 'classnames';
import { ViewInBrowserButton } from 'src/components/ModalWithGallery/components/ViewInBrowserButton';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { CloseButton } from 'src/components/ModalWithGallery/components/CloseButton';
import { CaretLeft, CaretRight, Stack, Image } from '@phosphor-icons/react';
import { ExternalModelThumbnail } from 'src/components/ExternalModelThumbnail';
import { ReferenceItem } from 'src/components/ModalWithGallery/components/ReferenceItem';
import { Markdown } from 'src/components/Markdown';
import { ReferenceIframe } from 'src/components/ModalWithGallery/components/ReferenceIframe';
import { CopyButton } from 'src/components/ModalWithGallery/components/CopyButton/CopyButton';
import { useHotkeys } from 'react-hotkeys-hook';
import { Icon } from 'src/components/Icon';
import { useBreakpoint, useDownloadFile, useVisible } from 'src/hooks';
import { ProgressBar } from 'src/components/ProgressBar';
import { MobileListButton } from 'src/components/ModalWithGallery/components/MobileListButton';
import { MobileModalOverlayList } from 'src/components/ModalWithGallery/components/MobileModalOverlayList';
import { useGetSharedData } from 'src/hooks';
import { CollapsibleTitle } from 'src/components/CollapsibleTitle';
import { DownloadButton } from 'src/components/ModalWithGallery/components/DownloadButton';
import { FILE_REGEX } from 'src/components/SharedMessage/SharedMessage';
import { AiGenerationStatus } from 'src/types/models/AiGenerationStatus';
import { MediaModalError } from './components/MediaModalError';
import { ImageGenData } from 'src/types/models/ImageGenData';

export const ModalWithGallery = () => {
  const { isMobileOrTablet } = useBreakpoint();
  const {
    isVisible: isOpenMobileListPanel,
    handleVisibilityRemove: closeMobileListPanel,
    handleVisibilityToggle: toggleIsOpenMobileListPanel,
  } = useVisible();
  const { downloadImage } = useDownloadFile();

  const { data } = useGetSharedData();

  const { original_query } = (data as SharedMessage) || {};

  const {
    isVisibleModalGallery,
    currentModalGalleryType,
    currentListOfGalleryItems,
    defaultSelectedGalleryIndex,
    onChangeModalGalleryData,
  } = useContext(SessionContext);
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    defaultSelectedGalleryIndex,
  );

  const swiperRef = useRef<SwiperRef | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const currentSelectedItem = useMemo(
    () => currentListOfGalleryItems[selectedItemIndex],
    [selectedItemIndex, currentListOfGalleryItems],
  );

  const handleChangeCurrentIndex = (index: number) => {
    if (isMobileOrTablet && isOpenMobileListPanel) {
      closeMobileListPanel();
    }
    setSelectedItemIndex(index);
    swiperRef?.current?.swiper?.slideTo(index);
  };

  useHotkeys('esc', () => {
    onChangeModalGalleryData(false, null, [], 0);
  });

  useHotkeys('left', () => {
    if (selectedItemIndex !== 0) {
      handleChangeCurrentIndex(selectedItemIndex - 1);
    }
  });

  useHotkeys('right', () => {
    if (selectedItemIndex + 1 !== currentListOfGalleryItems.length) {
      handleChangeCurrentIndex(selectedItemIndex + 1);
    }
  });

  useHotkeys('d', () => {
    if (currentModalGalleryType === ModalGalleryType.GENERATED_IMAGES) {
      downloadImage(currentSelectedItem.url);
    }
  });

  const handleSlideChange = () => {
    const direction = swiperRef?.current?.swiper.swipeDirection;

    if (direction === 'next') {
      if (selectedItemIndex + 1 !== currentListOfGalleryItems.length) {
        handleChangeCurrentIndex(selectedItemIndex + 1);
      }
    } else if (direction === 'prev') {
      if (selectedItemIndex !== 0) {
        handleChangeCurrentIndex(selectedItemIndex - 1);
      }
    }
  };

  const renderGalleryItem = (item: ModalGalleryItemType, index: number) => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return (
          <ResearchMediaThumbnail
            thumbnail={isSearchVideoReference(item) ? item.thumbnail : item.url}
            title={item.title}
            status={(item as ImageGenRenderData).status}
            isVideo={isSearchVideoReference(item)}
            onClick={() => handleChangeCurrentIndex(index)}
          />
        );
      case ModalGalleryType.EXTERNAL_MODELS:
        return isExternalModelReference(item) ? (
          <ExternalModelThumbnail
            isActive={index === selectedItemIndex}
            title={item.title}
            icon={item.icon}
            disabled={!item.content}
            onClick={() => handleChangeCurrentIndex(index)}
          />
        ) : null;
      case ModalGalleryType.REFERENCES:
        return isSearchWebPageReference(item) ? (
          <ReferenceItem
            isActive={index === selectedItemIndex}
            title={`${index + 1}. ${item.title}`}
            onClick={() => handleChangeCurrentIndex(index)}
          />
        ) : null;
      case ModalGalleryType.GENERATED_IMAGES:
        return isImageGeneratedItem(item) ? (
          <div className="nj-generated-image-wrapper">
            <h4 className="nj-generated-image-title">
              {item.icon && <Icon type={item.icon} size={20} />}
              <span className="text ellipsis">{item.title}</span>
            </h4>
            <ResearchMediaThumbnail
              thumbnail={item.url}
              status={(item as unknown as ImageGenRenderData).status}
              title={item.title}
              onClick={() => handleChangeCurrentIndex(index)}
            />
          </div>
        ) : null;
      default:
        return <></>;
    }
  };

  const renderTitle = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return (
          <h4 className="nj-modal-gallery-title-text ellipsis">
            {currentSelectedItem.title}
          </h4>
        );
      case ModalGalleryType.EXTERNAL_MODELS:
        return (
          <div className="nj-modal-overlay-content-title-wrapper">
            <Stack size={SVG_SIZE_XL} />
            <h4 className="nj-modal-gallery-title-text ellipsis">
              External model results
            </h4>
          </div>
        );
      case ModalGalleryType.REFERENCES:
        return (
          <div className="nj-modal-overlay-content-title-wrapper">
            {isSearchWebPageReference(currentSelectedItem) &&
              currentSelectedItem.favicon && (
                <img
                  src={currentSelectedItem.favicon}
                  alt={currentSelectedItem.title}
                  className="nj-modal-gallery-title-icon"
                />
              )}
            <h4 className="nj-modal-gallery-title-text">
              {currentSelectedItem.title}
            </h4>
          </div>
        );
      case ModalGalleryType.GENERATED_IMAGES:
        return (
          <div className="nj-modal-overlay-content-title-wrapper">
            <Image size={SVG_SIZE_XL} />
            <h4 className="nj-modal-gallery-title-text ellipsis">
              Image generators
            </h4>
          </div>
        );
      default:
        return <></>;
    }
  };

  const renderActivityButtons = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return <ViewInBrowserButton url={currentSelectedItem.url} />;
      case ModalGalleryType.EXTERNAL_MODELS:
        return (
          <>
            <CopyButton
              text={
                isExternalModelReference(currentSelectedItem)
                  ? `${currentSelectedItem.title} \n\n ${currentSelectedItem.content}`
                  : ''
              }
            />
          </>
        );
      case ModalGalleryType.REFERENCES:
        return <ViewInBrowserButton url={currentSelectedItem.url} />;
      case ModalGalleryType.GENERATED_IMAGES:
        return <DownloadButton url={currentSelectedItem.url} />;
      default:
        return <></>;
    }
  };

  const renderMainItemView = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        if (isSearchVideoReference(currentSelectedItem)) {
          return (
            <LazyReactPlayer
              url={currentSelectedItem.url}
              thumbnail={currentSelectedItem.thumbnail}
              width={isMobileOrTablet ? '90%' : '70%'}
              height={isMobileOrTablet ? '60%' : '90%'}
              className="nj-modal-gallery-player"
              controls
              onClick={(e: MouseEvent) => e.stopPropagation()}
            />
          );
        } else if (isSearchImageReference(currentSelectedItem)) {
          return (
            <img
              src={currentSelectedItem.url}
              alt={currentSelectedItem.title}
              className="nj-modal-gallery-image"
              onClick={(e) => e.stopPropagation()}
            />
          );
        }
        return <div></div>;
      case ModalGalleryType.EXTERNAL_MODELS:
        return isExternalModelReference(currentSelectedItem) ? (
          <div
            className="nj-modal-gallery-external-models-wrapper"
            id="printable-area"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="nj-modal-gallery-external-models-title">
              {currentSelectedItem.icon && (
                <Icon size={SVG_SIZE_XL} type={currentSelectedItem.icon} />
              )}

              <h4 className="nj-modal-gallery-external-models-title-text">
                {currentSelectedItem.title}
              </h4>
            </div>
            {currentSelectedItem.content ? (
              <div className={isMobileOrTablet ? '' : 'swiper-no-swiping'}>
                {original_query && (
                  <h4 className="nj-modal-gallery-external-models-title-text">
                    <CollapsibleTitle
                      query={original_query?.replace(FILE_REGEX, '')}
                    />
                  </h4>
                )}

                <Markdown>{currentSelectedItem.content}</Markdown>
              </div>
            ) : (
              <ProgressBar count={3} />
            )}
          </div>
        ) : null;
      case ModalGalleryType.REFERENCES:
        return (
          <ReferenceIframe
            url={currentSelectedItem.url}
            onClick={(e: MouseEvent) => e.stopPropagation()}
          />
        );
      case ModalGalleryType.GENERATED_IMAGES:
        return (currentSelectedItem as ImageGenRenderData).status ===
          AiGenerationStatus.SUCCESS ? (
          <div className="nj-modal-gallery-wrapper-image-gen">
            <img
              src={currentSelectedItem.url}
              alt={currentSelectedItem.title}
              className="nj-modal-gallery-image-gen"
              onClick={(e) => e.stopPropagation()}
            />
            <div className="nj-modal-gallery-image-gen--model-label">
              <Icon
                size={SVG_SIZE_L}
                type={
                  (currentSelectedItem as ImageGenRenderData).icon || undefined
                }
              />
              <span>{(currentSelectedItem as ImageGenRenderData).title}</span>
            </div>
          </div>
        ) : (
          <MediaModalError
            status={(currentSelectedItem as ImageGenData).status}
            mediaType="image"
          />
        );
      default:
        return <></>;
    }
  };

  const navigationButtons = (
    <>
      <button
        className="swiper-prev-button"
        disabled={selectedItemIndex === 0}
        onClick={(e) => {
          e.stopPropagation();

          handleChangeCurrentIndex(selectedItemIndex - 1);
        }}
      >
        <CaretLeft size={isMobileOrTablet ? SVG_SIZE_L : SVG_SIZE_XL} />
      </button>

      {isMobileOrTablet && (
        <MobileListButton onClick={toggleIsOpenMobileListPanel} />
      )}

      <button
        className="swiper-next-button"
        disabled={selectedItemIndex + 1 === currentListOfGalleryItems.length}
        onClick={(e) => {
          e.stopPropagation();

          handleChangeCurrentIndex(selectedItemIndex + 1);
        }}
      >
        <CaretRight size={isMobileOrTablet ? SVG_SIZE_L : SVG_SIZE_XL} />
      </button>
    </>
  );

  return (
    <CSSTransition
      in={isVisibleModalGallery}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-fade"
      onEnter={() => {
        setSelectedItemIndex(defaultSelectedGalleryIndex);
        if (swiperRef?.current?.swiper) {
          swiperRef?.current?.swiper?.slideTo(defaultSelectedGalleryIndex);
        }
      }}
      onExited={() => {
        setSelectedItemIndex(0);
      }}
      unmountOnExit
      nodeRef={containerRef}
    >
      <div className="nj-modal-overlay" ref={containerRef}>
        <div
          className="nj-modal-overlay-content"
          onClick={() => onChangeModalGalleryData(false, null, [], 0)}
        >
          <div className="nj-modal-overlay-content-header">
            <div className="nj-modal-overlay-content-title-wrapper">
              {renderTitle()}
            </div>
            <div className="nj-modal-overlay-content-activity-buttons-wrapper">
              {renderActivityButtons()}
              <CloseButton />
            </div>
          </div>
          {currentListOfGalleryItems.length > 0 && (
            <div
              className={classNames('nj-modal-overlay-content-body', {
                wide:
                  currentModalGalleryType !== ModalGalleryType.IMAGES_VIDEOS,
              })}
            >
              <Swiper
                centeredSlides={true}
                spaceBetween={50}
                slidesPerView={1}
                initialSlide={selectedItemIndex}
                direction="horizontal"
                className="nj-modal-content--carousel-container"
                ref={swiperRef}
                onSlideChange={handleSlideChange}
                allowSlideNext={isMobileOrTablet}
                allowSlidePrev={isMobileOrTablet}
              >
                {currentListOfGalleryItems.map((item) => (
                  <SwiperSlide key={item.url}>
                    {renderMainItemView()}
                  </SwiperSlide>
                ))}
                {!isMobileOrTablet && navigationButtons}
              </Swiper>
              {isMobileOrTablet && (
                <div className="nj-modal-overlay-content-footer">
                  {navigationButtons}
                </div>
              )}
            </div>
          )}
        </div>
        {!isMobileOrTablet && (
          <div className="nj-modal-overlay-right-side">
            {currentListOfGalleryItems.map((item, i) => (
              <div
                className={classNames('nj-modal-overlay-list-item-wrapper', {
                  selected: selectedItemIndex === i,
                })}
                key={`${item.url}_${i}`}
              >
                {renderGalleryItem(item, i)}
              </div>
            ))}
          </div>
        )}
        {isMobileOrTablet && (
          <MobileModalOverlayList
            isOpen={isOpenMobileListPanel}
            onClose={closeMobileListPanel}
          >
            <div className="nj-modal-overlay-mobile-list-panel">
              <div
                className={classNames(
                  'nj-modal-overlay-mobile-list-panel-body',
                  {
                    isGrid:
                      currentModalGalleryType ===
                      ModalGalleryType.IMAGES_VIDEOS,
                    isGeneratedImagesGrid:
                      currentModalGalleryType ===
                      ModalGalleryType.GENERATED_IMAGES,
                  },
                )}
              >
                {currentListOfGalleryItems.map((item, i) => (
                  <div
                    className={classNames(
                      'nj-modal-overlay-list-item-wrapper',
                      {
                        selected: selectedItemIndex === i,
                      },
                    )}
                    key={`${item.url}_${i}`}
                  >
                    {renderGalleryItem(item, i)}
                  </div>
                ))}
              </div>
            </div>
          </MobileModalOverlayList>
        )}
      </div>
    </CSSTransition>
  );
};

import { MaskSad, SmileySad } from '@phosphor-icons/react';
import cn from 'classnames';
import styles from './ResearchMediaError.module.scss';
import { SVG_SIZE_XL } from 'src/constants';
import { AiGenerationStatus } from 'src/types/models/AiGenerationStatus';
import { uppercaseFirstLetter } from 'src/utils';

interface ResearchMediaErrorProps {
  status?: string;
  isPlacesInThumbnail?: boolean;
  mediaType: string;
}

export const ResearchMediaError = ({
  status,
  mediaType,
  isPlacesInThumbnail,
}: ResearchMediaErrorProps) => {
  return (
    <div
      className={cn(styles.root, {
        [styles.withText]: isPlacesInThumbnail,
      })}
    >
      <div className={styles.errorContainer}>
        {status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ? (
          <>
            <MaskSad size={SVG_SIZE_XL} />
            {isPlacesInThumbnail && (
              <span>
                {uppercaseFirstLetter(mediaType)} violates content policies{' '}
              </span>
            )}
          </>
        ) : (
          <>
            <SmileySad size={SVG_SIZE_XL} />
            {isPlacesInThumbnail && (
              <span>
                <span>Unable to generate {mediaType}</span>
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

import { Icon as PhosphorIconType } from '@phosphor-icons/react';
import { SearchWebPageReference } from './api';

export enum MessageTabs {
  SEARCH_RESULT = 'search_result',
  DEEP_RESEARCH = 'deep_research',
}

export interface ExternalModelTabContent {
  tabTitle: string;
  iconName?: string;
  content: string;
  tabName: string;
  tabTitleClassName?: string;
}

export interface MessageTabData extends ExternalModelTabContent {
  references?: SearchWebPageReference[];
  IconComponent?: PhosphorIconType;
}

export type MediaType = 'image' | 'video';
